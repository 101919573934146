/* eslint-disable react/display-name */
import {DiagonalWrapperContext} from 'context/DiagonalWrapperContext';
import React from 'react';

import {SubcomponentsContentWrapperComponent} from 'lib/api/strapi';

import {
  DiagonalWrapper,
  TDiagonalWrapperConfig,
} from 'components/layout-components/DiagonalWrapper/DiagonalWrapper';

import strapiComponents from '../../components/strapi-components';

const absoluteComponents = ['typo-components.seperator', 'typo-components.teaser-slider'];
const disabledDiagonalComponents = [
  'typo-components.news-grid',
  'typo-components.hero-header',
  'typo-components.hero-header-with-bpr',
  // 'typo-components.title-with-optional-breadcrumb',
];
const _disabledComponents = ['typo-components.news-grid', 'typo-components.news-list'];

interface IComponentWithContentWrapper {
  [key: string]: any;
  contentWrapper?: SubcomponentsContentWrapperComponent;
}
const getAnchorConfig = (component: IComponentWithContentWrapper) => {
  const anchor_config = component?.contentWrapper?.anchor_config;
  if(anchor_config && !anchor_config?.anchor_id) {
    anchor_config.anchor_id = `${component.__component}-${component.id}`
  }
  return anchor_config;
}
const getComponent =
  (page, blogPage = false) =>
  (
    component: IComponentWithContentWrapper,
    index: number,
    allComponents: IComponentWithContentWrapper[],
  ) => {
    // console.log(allComponents);
    const Component = strapiComponents[component.__component] as any;
    if (!Component) {
      return <pre>{JSON.stringify(component, null, 2)}</pre>;
    }
    if(blogPage) {
      if( !component?.contentWrapper) {
        component.contentWrapper = {}
      }
      component.contentWrapper.diagonal = false
      component.contentWrapper.backgroundColor = "white";
      component.contentWrapper.backgroundImage = null;
    } 
    const anchor_config = getAnchorConfig(component);
    const anchorConfigList = allComponents.filter(comp => comp?.contentWrapper?.anchor_config).map(getAnchorConfig);
    const absoluteWithBackground = ['typo-components.teaser-grid'].includes(component.__component);
    const noPaddingElement = blogPage || ['typo-components.title-with-optional-breadcrumb'].includes(
      component.__component,
    );
    const noPaddingTop =
      allComponents?.[0]?.__component === 'typo-components.title-with-optional-breadcrumb' &&
      allComponents?.[1]?.id === component?.id;
    let background = component?.contentWrapper?.backgroundColor;
    const padding = component?.contentWrapper?.padding;
    const margin = component?.contentWrapper?.margin;
    const backgroundImage =
      (component?.backgroundImageScreen?.data?.attributes?.url &&
        `url(${component?.backgroundImageScreen?.data?.attributes?.url})`) ||
      (component?.contentWrapper?.backgroundImage?.data?.attributes?.url
        ? `url(${component?.contentWrapper?.backgroundImage?.data.attributes.url})`
        : null);
    const backgroundImageMobile =
      (component?.backgroundImageMobile?.data?.attributes?.url &&
        `url(${component?.backgroundImageMobile?.data?.attributes?.url})`) ||
      null;
    if(component?.__component === 'menu-components.anchor-link-list' && anchorConfigList?.length) {
      component.anchorConfigList = anchorConfigList
    }
    // console.log(component);
    return Component ? (
      <DiagonalWrapper
        margin={margin}
        padding={padding}
        backgroundImageMobile={backgroundImageMobile}
        absoluteWithBackground={absoluteWithBackground}
        config={generateWrapperConfig(component, index, allComponents, blogPage)}
        backgroundImage={backgroundImage}
        key={`${page.slug || page.id}--${component.__component}-${component.id}`}
        background={background}
        noPadding={noPaddingElement}
        noPaddingTop={noPaddingTop}
        anchor_config={anchor_config}
      >
        <DiagonalWrapperContext.Provider
          value={generateWrapperConfig(component, index, allComponents, blogPage)}
        >
          <Component {...component} />
        </DiagonalWrapperContext.Provider>
      </DiagonalWrapper>
    ) : null;
  };
// needs !important to override styles
const componentsAbsoluteHeightMapping = {
  'typo-components.teaser-slider': {
    top: {xxs: `150px !important`, md: `200px !important`},
    bottom: {xxs: `150px !important`, md: `200px !important`},
  },
  'typo-components.teaser-grid': {top: '100px !important'},
  'typo-components.seperator': {
    top: {xxs: '60px !important', xl: '120px !important'},
    bottom: '120px !important',
  },
};
export const generateWrapperConfig = (
  component?: IComponentWithContentWrapper,
  index?: number,
  allComponents?: IComponentWithContentWrapper[],
  blogPage?: boolean
) => {
  const defaultConfig = {
    absolute: component && absoluteComponents.includes(component.__component),
    diagonal: {top: false, bottom: false},
    topElement: {isDiagonal: false, isAbsolute: false},
    botElement: {isDiagonal: false, isAbsolute: false},
    disabled: component && disabledDiagonalComponents.includes(component.__component),
  } as TDiagonalWrapperConfig;
  if (!component || (!index && index !== 0) || !allComponents) {
    return defaultConfig;
  }
  let topComponent = allComponents[index - 1];
  const botComponent = allComponents[index + 1];
  if (topComponent) {
    defaultConfig.topElement.background = topComponent?.contentWrapper?.backgroundColor || null;
    defaultConfig.topElement.absoluteHeight =
      componentsAbsoluteHeightMapping[topComponent?.__component] || {};
    if (absoluteComponents.includes(topComponent?.__component)) {
      defaultConfig.topElement.isAbsolute = true;
      topComponent = allComponents?.[index - 2] || topComponent;
    }
    if (
      topComponent?.contentWrapper?.diagonal ||
      disabledDiagonalComponents.includes(topComponent?.__component)
    ) {
      defaultConfig.topElement.isDiagonal = true;
    }
  }
  if (botComponent) {
    defaultConfig.botElement.background = botComponent?.contentWrapper?.backgroundColor || null;

    defaultConfig.botElement.absoluteHeight =
      componentsAbsoluteHeightMapping[botComponent?.__component] || {};
    if (absoluteComponents.includes(botComponent?.__component)) {
      defaultConfig.botElement.isAbsolute = true;
    }
    if (botComponent?.contentWrapper?.diagonal) {
      defaultConfig.botElement.isDiagonal = true;
    }
  }
  if (component.contentWrapper) {
    const {contentWrapper} = component;
    if (contentWrapper.diagonal) {
      defaultConfig.diagonal = {top: true, bottom: true};
    }
    const isFirstElement = index === 0;
    const isLastElement = index === allComponents.length - 1;
    if (isFirstElement) {
      defaultConfig.diagonal.top = false;
    }
    if (isLastElement) {
      defaultConfig.diagonal.bottom = false;
    }
  }
  return defaultConfig;
};
export default getComponent;
