import clsx from 'clsx';
import {BackgroundStyleContext} from 'context/BackgroundStyleContext';
import {useStaticBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import React, {useMemo} from 'react';

import styled from '@emotion/styled';

import {
  SubcomponentsContentWrapperComponentAnchorConfig,
  SubcomponentsContentWrapperComponentBackgroundColor,
  SubcomponentsContentWrapperComponentMargin,
  SubcomponentsContentWrapperComponentPadding,
} from 'lib/api/strapi';

import {Box} from 'components/basic-components';

import styles from './DiagonalWrapper.module.scss';

export type TDiagonalWrapperConfig = {
  absolute: boolean;
  diagonal: {
    top: boolean;
    bottom: boolean;
  };
  topElement: {
    isDiagonal: boolean;
    isAbsolute: boolean;
    background?: string;
    absoluteHeight?: {top: string; bottom: string};
  };
  botElement: {
    isDiagonal: boolean;
    isAbsolute: boolean;
    background?: string;
    absoluteHeight?: {top: string; bottom: string};
  };
  disabled: boolean;
};
const getContentPaddingMargin = ({botAbsoluteHeight, topAbsoluteHeight, padding, margin}) => {
  return {
    paddingBottom:
      (padding?.bottom && `${padding.bottom}px !important`) || botAbsoluteHeight?.top || undefined,
    paddingTop:
      (padding?.top && `${padding.top}px !important`) || topAbsoluteHeight?.bottom || undefined,
    paddingLeft: (padding?.left && `${padding.left}px !important`) || undefined,
    paddingRight: (padding?.right && `${padding.right}px !important`) || undefined,
    marginBottom: (margin?.bottom && `${margin.bottom}px !important`) || undefined,
    marginTop: (margin?.top && `${margin.top}px !important`) || undefined,
    marginLeft: (margin?.left && `${margin.left}px !important`) || undefined,
    marginRight: (margin?.right && `${margin.right}px !important`) || undefined,
  };
};
export const DiagonalWrapper = ({
  children,
  absoluteWithBackground,
  padding,
  margin,
  anchor_config,
  config: {
    absolute,
    diagonal: {top, bottom},
    topElement: {
      background: topBackground,
      isDiagonal: topIsDiagonal,
      isAbsolute: _topIsAbsolute,
      absoluteHeight: topAbsoluteHeight,
    },
    botElement: {
      background: botBackground,
      isDiagonal: botIsDiagonal,
      isAbsolute: _botIsAbsolute,
      absoluteHeight: botAbsoluteHeight,
    },
    disabled,
  } = {
    absolute: false,
    diagonal: {top: false, bottom: false},
    topElement: {isDiagonal: false, isAbsolute: false},
    botElement: {isDiagonal: false, isAbsolute: false},
    disabled: false,
  },
  background,
  backgroundImage,
  backgroundImageMobile,
  diagonalBackgroundImage,
  noPadding = false,
  noPaddingTop = false,
}: {
  children: React.ReactElement[] | React.ReactElement;
  absoluteWithBackground?: boolean; // Absolute component that overlays adjecent components but still has a background
  // to disable the diagonal styles
  // the background css
  padding?: SubcomponentsContentWrapperComponentPadding;
  margin?: SubcomponentsContentWrapperComponentMargin;
  background?: SubcomponentsContentWrapperComponentBackgroundColor;
  backgroundImage?: string | null;
  backgroundImageMobile?: string | null;
  diagonalBackgroundImage?: boolean;
  noPadding?: boolean;
  noPaddingTop?: boolean;
  anchor_config?: SubcomponentsContentWrapperComponentAnchorConfig
  //if its the first item
  // if its last item
  config: TDiagonalWrapperConfig;
}) => {
  const {backgroundColor} = useStaticBackgroundStyleConfigs(background);
  const contentStyles = useMemo(
    () => getContentPaddingMargin({botAbsoluteHeight, topAbsoluteHeight, padding, margin}),
    [botAbsoluteHeight, topAbsoluteHeight, padding, margin],
  );

  if (disabled) {
    return children;
  }

  return (
    <>
      {absoluteWithBackground ? (
        <Box sx={{maxWidth: '1410px', margin: '0 auto', position: 'relative'}}>
          <Box sx={{position: 'absolute', width: '100%'}}>{children}</Box>
        </Box>
      ) : null}
      <Wrapper
        className={clsx({
          [styles.diagonal]: (top && bottom) || (topIsDiagonal && botIsDiagonal && !absolute),
          [styles.bottom]: bottom && !top,
          [styles.top]: top && !bottom,
          [styles.topDiagonal]: topIsDiagonal,
          // [styles.absolute]: absolute
        })}
        background={!diagonalBackgroundImage && (backgroundImage || backgroundColor)}
        backgroundMobile={
          !diagonalBackgroundImage && (backgroundImageMobile || backgroundImage || backgroundColor)
        }
        top={!diagonalBackgroundImage && top && !bottom}
        bottom={!diagonalBackgroundImage && bottom && !top}
      >
        <span className={styles.anchorLink} ><span id={anchor_config?.anchor_id} /></span>
        <Box
          sx={diagonalBackgroundImage ? {'&:before': {background: backgroundImage}} : {}}
          className={clsx({[styles.innerBackground]: diagonalBackgroundImage})}
        >
          <BackgroundStyleContext.Provider value={{theme: background}}>
            <Box
              className={clsx({
                [styles.content]: true,
                [styles.maxWidth]: true,
                [styles.topPadding]:
                  !noPaddingTop &&
                  !noPadding &&
                  background !== topBackground &&
                  !absolute &&
                  !absoluteWithBackground,
                [styles.botPadding]:
                  !noPadding &&
                  background !== botBackground &&
                  !absolute &&
                  !absoluteWithBackground,
              })}
              sx={contentStyles}
            >
              <Box
                sx={absoluteWithBackground ? {visibility: 'hidden'} : {}}
                className={clsx({
                  [styles.absolute]: absolute,
                })}
              >
                {children}
              </Box>
            </Box>
          </BackgroundStyleContext.Provider>
        </Box>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div<{
  background?: string;
  top?: boolean;
  bottom?: boolean;
  backgroundMobile?: string;
}>`
  &&& {
    ${props => (props.top ? `background: ${props?.backgroundMobile} ;` : '')}
  }
  ${props => (props.background ? `background: ${props?.backgroundMobile};` : '')} // SKEWY variant
  @media only screen and (min-width:770px) {
    &&& {
      ${props => (props.top ? `background: ${props.background} ;` : '')}
    }
    ${props => (props.background ? `background: ${props.background};` : '')}// SKEWY variant
  }
`;
